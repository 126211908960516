.header{
    display: flex;
    justify-content: space-between;
}
.logo{
    width:10rem;
    height: 3rem;
}
.header-menu{
    list-style: none;
    display: flex;
    gap: 2rem;
    color: white;

}
.header-menu>li:hover{
    cursor: pointer;
    color:var(--orange)
}
.hero-text-4{
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
    color: white;
}